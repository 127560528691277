<template>
  <div>
    <flixExpand :settings="{open: true, bottomButton: false, buttonClass: 'flix-btn flix-btn-default flix-btn-block'}">
      <template v-slot:button>
        {{ $t('message.signUp') }}
      </template>
      <template v-slot:expand>
        <signUp :callback="function(){ setSignIn() }"/>
      </template>
    </flixExpand>
    <flixExpand :settings="{bottomButton: false, buttonClass: 'flix-btn flix-btn-default flix-btn-block'}">
      <template v-slot:button>
        {{ $t('message.alreadyAccount') }}
      </template>
      <template v-slot:expand>
        <signIn :callback="function(){ setSignIn() }"/>
      </template>
    </flixExpand>
  </div>
</template>
<script>
export default {
  components: {
    signIn () { return import('@/components/signIn/form') },
    signUp () { return import('@/components/signIn/register') }
  },
  props: {
    saveCalendar: Function
  },
  data () {
    return {

    }
  },
  methods: {
    setSignIn () {
      this.saveCalendar()
      this.$router.push({ name: 'dashboard' })
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
